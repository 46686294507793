export default {
    path: "/suppliers",
    name: "suppliers",
    component: () => import("@/views/suppliers"),
    //mdiTruckDeliveryOutline
    icon : "mdi mdi-truck-delivery-outline",
    to : "/suppliers",
    hiddenChildren: true,
    meta: {
        permission: "Index-supplier",
        },

} 