import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "@/layouts/dashboard.vue";
import { asyncRoutes } from "./asyncRoutes";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    name: "layout",
    redirect: "/dashboard",
    meta: {
      title: "dashboard",
    },
    children: [...asyncRoutes],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "*",
    component: () => import("@/views/404"),
    name: "Page404",

    meta: {
      title: "Page404",
    },
  },
  {
    name: "pos",
    path: "/app/pos",
    // beforeEnter: authenticate,
    component: () => import(/* webpackChunkName: "pos" */ "@/views/pos"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const token = store.getters["user/getToken"];
  if (to.name !== "Login" && !token) next({ name: "Login" });
  else next();
});
export default router;
