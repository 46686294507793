export default {
  path: "/payment-methods",
  name: "payment-methods",
  component: () => import("@/views/paymentMethods"),
  icon: "mdi mdi-truck-delivery-outline",
  to: "/payment-methods",
  hiddenChildren: true,
  meta: {
    permission: "Index-payment_method",
  },
};
