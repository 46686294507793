export default {
  path: "/product",
  name: "product",
  component: () => import("@/views/product"),
  redirect: "/product/list",
  icon: "mdi mdi-shopping-outline",
  to: "/product",
  meta: {
    permission: "Index-product",
  },
  children: [
    {
      path: "list",
      name: "Product List",
      component: () => import("@/views/product/record"),
      to: "/product/list",
      icon: "nav-icon i-Files",
      meta: {
        permission: "Index-product",
      },
    },

    {
      path: "tax",
      name: "Product Tax",
      component: () => import("@/views/product/tax"),
      to: "/product/tax",
      icon: "nav-icon i-Files",
      meta: {
        permission: "Index-tax",
      },
    },
    {
      path: "unit",
      name: "Product Unit",
      component: () => import("@/views/product/unit"),
      to: "/product/unit",
      icon: "nav-icon i-Files",
      meta: {
        permission: "Index-unit",
      },
    },
    {
      path: "quantity-type",
      name: "Product Quantity Type",
      component: () => import("@/views/product/quantityType"),
      to: "/product/quantity-type",
      icon: "nav-icon i-Files",
      meta: {
        permission: "Index-quantity_types",
      },
    },
    {
      path: "add",
      name: "Create Product",
      component: () => import("@/views/product/action/index.vue"),
      to: "/product/add",
      icon: "nav-icon i-Files",
      meta: {
        permission: "Create-product",
      },
    },
    {
      path: ":id/edit",
      name: "Edit Product",
      component: () => import("@/views/product/action/index.vue"),
      to: "/product/add",
      icon: "nav-icon i-Files",
      hideInMenu: true,
      meta: {
        permission: "Edit-product",
      },
    },
    {
      path: "detail/:id",
      name: "Product Details",
      component: () => import("@/views/product/details/index.vue"),
      to: "/product/detail",
      icon: "nav-icon i-Files",
      hideInMenu: true,
      meta: {
        permission: "Index-product",
      },
    },
  ],
};
