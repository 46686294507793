export default {
  path: "/categories",
  name: "categories",
  component: () => import("@/views/categories"),
  //mdiScatterPlotOutline
  icon: "mdi mdi-scatter-plot-outline",
  to: "/categories",
  hiddenChildren: true,
  meta: {
    permission: "Index-category",
  },
};
