<template>
  <div>
    <div class="breadcrumb">
      <slot name="header">
        <h1>{{ page }}</h1>
        <ul v-if="folder">
          <li>
            <!-- <a href=""> {{ folder }} </a>
             -->
            <router-link :to="folderUrl"> {{ folder }} </router-link>
          </li>
          <li>{{ page }}</li>
        </ul>
      </slot>
    </div>
    <div class="separator-breadcrumb border-top"></div>
  </div>
</template>
<script>
export default {
  props :{
    page: {
      type: String,
      required: true,
    },
    folder: {
      type: String,
      required: false,
    },
    folderUrl: {
      type: String,
      required: false,
    },
  }
};
</script>
