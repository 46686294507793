export default {
    path: "/expense-type",
    name: "expense-type",
    component: () => import("@/views/expenseType"),
    icon : "mdi mdi-cash-multiple",
    to : "/expense-type",
    hiddenChildren: true,
    meta: {
        permission: "Index-expense_types",
      },
} 