export default {
  path: "/managers",
  name: "managers",
  component: () => import("@/views/managers"),
  to: "/managers",
  icon: "mdi mdi-account-group-outline",  hiddenChildren: true,
  meta: {
    permission: "Index-manager",
  },
};
