<template>
  <div class="app-admin-wrap layout-sidebar-large clearfix">
    <!-- <customizer></customizer> -->
    <TopNav />
    <side-bar />
    <main>
      <div
        :class="{ 'sidenav-open': getSideBarToggleProperties.isSideNavOpen }"
        class="main-content-wrap d-flex flex-column flex-grow-1"
      >
        <transition name="page" mode="out-in">
          <router-view />
        </transition>

        <div class="flex-grow-1"></div>
        <!-- <appFooter /> -->
      </div>
    </main>
  </div>

  <!-- <appFooter /> -->
</template>

<script>
import TopNav from "@/components/common/TopNav";
import SideBar from "@/components/common/SideBar";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    TopNav,
    SideBar,
  },
  computed: {
    ...mapGetters(["getSideBarToggleProperties"]),
  },
};
</script>

<style></style>
