export default {
  path: "/receipt",
  name: "receipt",
  component: () => import("@/views/receipt"),
  redirect: "/receipt/list",
  //mdiAccountOutline
  icon: "mdi mdi-receipt-text",
  to: "/receipt",
  meta: {
    permission: "Index-receipt",
  },
  children: [
    {
      path: "list",
      name: "Receipt List",
      component: () => import("@/views/receipt/record"),
      to: "/receipt/list",
      icon: "nav-icon i-Files",
      meta: {
        permission: "Index-receipt",
      },
    },
    {
      path: "details/:id",
      name: "Receipt Detail",
      component: () => import("@/views/receipt/details"),
      to: "/receipt/detail",
      icon: "nav-icon i-Files",
      hideInMenu: true,
      meta: {
        permission: "Index-receipt",
      },
    },
    {
      path: "add",
      name: "Create Receipt",
      component: () => import("@/views/receipt/action"),
      to: "/receipt/add",
      icon: "nav-icon i-Files",
      meta: {
        permission: "Create-receipt",
      },
    },
    {
      path: ":id/edit",
      name: "Edit Receipt",
      component: () => import("@/views/receipt/action"),
      to: "/receipt/add",
      icon: "nav-icon i-Files",
      hideInMenu: true,
      meta: {
        permission: "Edit-receipt",
      },
    },
  ],
};
