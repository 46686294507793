
export default {
  namespaced: true,

  state: () => ({
    routes: [],
  }),
  mutations: {
    SET_ROUTES(state, payload) {
      state.routes = payload;
    },


  },
  actions: {
    setAllowedRoutes({ commit }, allowedRoutes) {
      commit("SET_ROUTES", allowedRoutes);
    },
  },
  getters: {
    getRoutes(state) {
      return state.routes;
    },

  }
};