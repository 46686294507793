<template>
  <ValidationProvider
    ref="ValidationProvider_ref"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <b-form-group :label="$attrs.label" slot-scope="{ valid, errors }">
      <slot
        :attrs="{
          state:
            (serverErrors && serverErrors[$attrs.errorName]) || errors[0]
              ? false
              : valid && $refs.ValidationProvider_ref?.flags?.dirty
              ? true
              : null,
        }"
      />
      <b-form-invalid-feedback>
        {{ (serverErrors && serverErrors[$attrs.errorName][0]) || errors[0] }}
      </b-form-invalid-feedback>
      <!-- <b-form-valid-feedback  :state="!errors[0] && $refs.ValidationProvider_ref?.flags?.dirty && !(serverErrors && serverErrors[$attrs.errorName])
                ? true : null
                
                " class="d-flex justify-center align-center success-msg" :style="`top:${top}px`">
                  <p class="mb-0 mx-1">
                    {{$t('validation.success')}}
                  </p>
                  <img  width="20" height="20"  :src="require('/src/assets/imgs/icons/success.svg')"> 
                </b-form-valid-feedback> 
            -->
    </b-form-group>

    <!-- <p>
    <pre>
      {{errors}}
    </pre>
  </p> -->
  </ValidationProvider>
</template>

<script>
export default {
  name: "FormGroup",
  inheritAttrs: false,
  props: {
    top: {
      type: String,
      default: "",
    },
  },
  computed: {
    serverErrors() {
      return this.$store.state.serverErrors;
    },
  },
  methods: {
    validateField(callbackFunction) {
      this.$refs.ValidationProvider_ref.validate().then(({ valid }) => {
        console.log(this.$refs.ValidationProvider_ref);
        if (valid) {
          callbackFunction();
        }
      });
    },
  },
};
</script>
