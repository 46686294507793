export default {
  path: '/invoice',
  name: 'invoice',
  component: () => import('@/views/invoice'),
  //mdiAccountOutline
  icon: 'mdi mdi-cart-outline',
  to: '/invoice',
  meta: {
    permission: 'Index-invoice',
  },
  children: [
    {
      path: '/invoice',
      name: 'Purchase List',
      component: () => import('@/views/invoice/record'),
      to: '/invoice',
      icon: 'nav-icon i-Files',
      meta: {
        permission: 'Index-invoice',
      },
    },
    {
      path: 'details/:id',
      name: 'Invoice Detail',
      component: () => import('@/views/invoice/details'),
      to: '/invoice/detail',
      icon: 'nav-icon i-Files',
      hideInMenu: true,
      meta: {
        permission: 'Index-invoice',
      },
    },
    {
      path: 'add',
      name: 'Create Invoice',
      component: () => import('@/views/invoice/action'),
      to: '/invoice/add',
      icon: 'nav-icon i-Files',
      meta: {
        permission: 'Create-invoice',
      },
    },
    {
      path: ':id/edit',
      name: 'Edit Invoice',
      component: () => import('@/views/invoice/action'),
      to: '/invoice/:id/edit',
      icon: 'nav-icon i-Files',
      hideInMenu: true,
      meta: {
        permission: 'Edit-invoice',
      },
    },
  ],
};
