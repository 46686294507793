export default {
  path: "/admin",
  name: "admin",
  component: () => import("@/views/admins"),
  icon: "mdi mdi-shield-crown-outline",
  to: "/admin",
  hiddenChildren: true,
  meta:{
    permission: "Index-admin"
  }  
};
