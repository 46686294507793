<template>
  <ValidationProvider
    ref="ValidationProvider_ref"
    v-bind="$attrs"
    v-on="$listeners"
    #default="{ errors }"
    class="form-group"
  >
    <label style="font-size: 14px; font-weight: 500; color: black">{{
      $attrs.label
    }}</label>

    <slot
      :atts="
        !errors[0] &&
        $refs.ValidationProvider_ref?.flags?.dirty &&
        !(serverErrors && serverErrors[$attrs.errorName])
          ? 'valid'
          : (serverErrors && serverErrors[$attrs.errorName]) || errors[0]
          ? 'invalid'
          : null
      "
    >
    </slot>
    <span 
      style="color: #ef4444; margin-top:0.25rem;"
    >
      {{ errors[0] }}
    </span>
  </ValidationProvider>
</template>
<script>
export default {
  inheritAttrs: false,
  computed: {
    serverErrors() {
      return this.$store.state.serverErrors;
    },
  },
};
</script>

<style lang="scss"></style>
