export default {
  path: "/branchs",
  name: "branchs",
  component: () => import("@/views/branchs"),
  icon: "mdi mdi-source-branch",
  to: "/branchs",
  hiddenChildren: true,
  meta: {
    permission: "Index-branch",
  },
};
