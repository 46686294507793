export default {
  path: "/transfer",
  name: "transfers",
  component: () => import("@/views/transfers"),
  //mdiAccountOutline
  icon: "mdi mdi-transfer",
  to: "/transfer/list",
  meta: {
    permission: "Index-transaction",
  },
  children: [
    {
      path: "list",
      name: "Transfer List",
      component: () => import("@/views/transfers/record"),
      icon: "mdi mdi-transfer",
      to: "/transfer/list",
      meta: {
        permission: "Index-transaction",
      },
    },
    {
      path: "add",
      name: "Create Transfer",
      component: () => import("@/views/transfers/action"),
      to: "/transfer/add",
      icon: "nav-icon i-Files",
      meta: {
        permission: "Create-transaction",
      },
    },
    {
      path: ":id/edit",
      name: "Edit Transfer",
      component: () => import("@/views/transfers/action"),
      to: "/transfer/:id/edit",
      icon: "nav-icon i-Files",
      hideInMenu: true,
      meta: {
        permission: "Edit-transaction",
      },
    },
  ],
};
