import Vuex from "vuex";
import Vue from "vue";
import largeSidebar from "./modules/largeSidebar";
import compactSidebar from "./modules/compactSidebar";
import config from "./modules/config";
import auth from "./modules/auth";
import language from "./modules/language";
import user from "./modules/user";
import routes from "./modules/routes";
import axios from "@/plugins/axios"
// Load Vuex
Vue.use(Vuex);
// Create store
export default new Vuex.Store({
  modules: {
    language,
    auth,
    largeSidebar,
    compactSidebar,
    config,
    user,
    routes
  },
    state: {
      serverErrors: null,
    },
    mutations: {
      SET_SERVER_ERRORS(state, payload) {
        state.serverErrors = payload;
      },
      CLEAR_SERVER_ERRORS(state) {
        state.serverErrors = null;
      },
    },
    actions: {
      SetServerErrors({ commit }, payload) {
        return new Promise((resolve) => {
          commit("SET_SERVER_ERRORS", payload);
          resolve(true);
        });
      },
      ClearServerErrors({ commit }) {
        return new Promise((resolve) => {
          commit("CLEAR_SERVER_ERRORS");
          resolve(true);
        });
      },
      login(_, payload){
        console.log(axios)
        return new Promise((resolve, reject) => {
          axios.post("/admin/login", payload).then(response => {
            const { data } = response;
            resolve(data);
          }).catch(error => {
            reject(error);
          });
        });
      }
    },
});
