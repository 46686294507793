import Vue from "vue";
import {
    ValidationObserver,
    ValidationProvider,
    extend,
} from "vee-validate";
import {i18n} from "./i18n";
import * as rules from "vee-validate/dist/rules";
import FormGroup from "@/components/FormGroup";


Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("FormGroup", FormGroup);
const customMessages = {
    getMessage(_, values) {
      let mimesRule;
      if (typeof values == "object") {
        const spreadData = (({ _field_, _value_, _rule_, ...values }) => values)(
          values
        );
        mimesRule = Object.values(spreadData).join(",");
      }
  
      return i18n.t(`validation.${values._rule_}`, {
        field: i18n.t(`fields.${values._field_}`),
        max: values.length,
        min: values.length,
        max_value: values.max,
        comparedField: i18n.t(`fields.${values.comparedField}`),
        mimes: mimesRule,
        size: values.size
      });
    }
  };

Object.keys(rules).forEach((rule) => {
    extend(rule, {
        ...rules[rule],
        message: customMessages.getMessage,
    });
});
