import Vue from "vue";
import { mapState } from "vuex";
const globalMixen =  {
  // inject toast in root
  methods: {
    makeToast(variant, msg, title) {
      this.$root.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    ...mapState({
      serverErrors: "serverErrors",
    }),
  }
};

Vue.mixin(globalMixen);
