export default {
    path: "/clients",
    name: "clients",
    component: () => import("@/views/clients"),
    //mdiAccountOutline
    icon : "mdi mdi-account-outline",
    to : "/clients",
    hiddenChildren: true,
    meta: {
        permission: "Index-client",
      },
} 