export default {
  path: "/super-admins",
  name: "super-admins",
  component: () => import("@/views/superAdmins"),
  to: "/super-admins",
  icon: "mdi mdi-account-outline",
  hiddenChildren: true,
  meta: {
    permission: "Index-superAdmin",
  },
};
