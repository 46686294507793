var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',_vm._g(_vm._b({ref:"ValidationProvider_ref",staticClass:"form-group",scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticStyle:{"font-size":"14px","font-weight":"500","color":"black"}},[_vm._v(_vm._s(_vm.$attrs.label))]),_vm._t("default",null,{"atts":!errors[0] &&
      _vm.$refs.ValidationProvider_ref?.flags?.dirty &&
      !(_vm.serverErrors && _vm.serverErrors[_vm.$attrs.errorName])
        ? 'valid'
        : (_vm.serverErrors && _vm.serverErrors[_vm.$attrs.errorName]) || errors[0]
        ? 'invalid'
        : null}),_c('span',{staticStyle:{"color":"#ef4444","margin-top":"0.25rem"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)},'ValidationProvider',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }