// import { login, userInfo } from "@/api/login";
// import { getToken, setToken, removeToken } from "@/utils/auth";
// import router from "@/router";
import { asyncRoutes } from '@/router/asyncRoutes';
export default {
  namespaced: true,
  state: {
    // token: getToken(),
    // language: Cookies.get("language") || "ar",
    token: localStorage.getItem('token'),
    user: '',
    permissions: [],
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    RESET_STATE(state) {
      state.user = '';
    },

    SET_TOKEN(state, token) {
      state.token = token;
      console.log(state.token);
      localStorage.setItem('token', token);
    },
    SET_PERMISSIONS(state, payload) {
      state.permissions = payload;
      localStorage.setItem('permissions', JSON.stringify(payload));
    },
  },
  actions: {
    setPermissions({ commit, dispatch }, routers) {
      dispatch('routes/setAllowedRoutes', routers, { root: true });
    },
    routesGenerator({ commit, dispatch, state }, payload) {
      let routes = asyncRoutes;

      if (payload) {
        dispatch('setPermissions', asyncRoutes);
        return;
      }
      let routesAvailable = [];
      const recusiveRoutes = (routes) => {
        routes.forEach((route) => {
          if (state.permissions.includes(route.meta?.permission)) {
            route.available = true;
            if (route.children) {
              recusiveRoutes(route.children);
            }
          }
        });
      };
      recusiveRoutes(routes);
      routesAvailable = routes.filter((route) => route.available);
      if (routesAvailable.length) {
        routesAvailable = asyncRoutes;
      }
      dispatch('setPermissions', routesAvailable);
    },
    // Rmove all in local storage

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '');
        commit('RESET_STATE');
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        return resolve();
      });
    },
    // Logout({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     commit("SET_TOKEN", "");
    //     removeToken();
    //     Cookies.remove("permissions");
    //     return resolve();
    //   });
    // },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      return state.token;
    },
  },
};
