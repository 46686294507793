export default {
    path: "/companies",
    name: "companies",
    component: () => import("@/views/companies"),
    icon : "mdi mdi-domain",
    to : "/companies",
    hiddenChildren: true,
    meta: {
        permission: "Index-company",
      },
} 