export default {
    path: "/location",
    name: "location",
    component: () => import("@/views/location"),
    redirect: "/location/countries",
    icon : "mdi mdi-map-legend",
    to : "/location/countries",
    meta: {
        permission: "Index-country"
      },
    children: [
        {
            path: "countries",
            name: "countries",
            component: () =>
                import("@/views/location/countries.vue"),
            to: "/location/countries",
            icon :"mdi mdi-city-variant-outline" ,
            meta: {
                permission: "Index-country"
              },
        },
        // can ve /cities or /cities/:id
        {
            path: "cities/:id?",
            name: "cities",
            component: () =>
                import("@/views/location/cities.vue"),
            to: "/location/cities",
            icon : "mdi mdi-city-variant-outline",
            meta: {
                permission: "Index-city"
            },
        },
        {
            // path be / areas Or areas/idcity/idcounty
            path: "areas/:id_city?",
            name: "areas",
            component: () =>
                import("@/views/location/areas.vue"),
            to: "/location/areas",
            icon : "mdi mdi-city-variant-outline" ,
            meta: {
                permission: "Index-city"
            },
        }
    ]
}