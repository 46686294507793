<template>
  <div
    class="side-content-wrap"
    @mouseenter="isMenuOver = true"
    @mouseleave="isMenuOver = false"
    @touchstart="isMenuOver = true"
  >
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      :class="{ open: getSideBarToggleProperties.isSideNavOpen }"
      ref="myData"
      class="sidebar-left rtl-ps-none ps scroll"
    >
      <div>
        <ul class="navigation-left" :class="{ hover: 'w-auto' }">
          <li
            v-for="route in getRoutes"
            :key="route.path"
            @click="toggleSubMenu($event)"
            @mouseenter="toggleMouseMove($event)"
            :class="{
              active:
                route.path.split('/').filter((x) => x !== '')[0] === selectedParentMenu,
            }"
            class="nav-item d-flex justify-content-start ml-4"
            :data-item="route.name"
            :data-submenu="
              route.children && route.children?.length > 0 && !route.hiddenChildren
            "
          >
            <router-link tag="a" class="nav-item-hold w-auto" :to="route.to">
              <i :class="route.icon" class="font-size-20 mr-2"></i>
              <span class="mb-1">{{ $t(`nav.${route.name}`) }}</span>
            </router-link>
          </li>
          <!-- <li
            v-show="
              currentUserPermissions &&
              (currentUserPermissions.includes('products_add') ||
                currentUserPermissions.includes('products_view') ||
                currentUserPermissions.includes('barcode_view'))
            "
            @mouseenter="toggleSubMenu"
            class="nav-item"
            :class="{ active: selectedParentMenu == 'products' }"
            data-item="products"
            :data-submenu="true"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-Library-2"></i>
              <span class="nav-text">{{ $t("Products") }}</span>
            </a>
          </li> -->
        </ul>
      </div>
    </vue-perfect-scrollbar>
    <vue-perfect-scrollbar
      :class="{ open: openSubMenu }"
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      @mouseleave="openSubMenu = false"
      class="sidebar-left-secondary ps rtl-ps-none"
    >
      <div ref="sidebarChild">
        <ul
          class="childNav d-none"
          data-parent="hrm"
          :class="{ 'd-block': subRoute.length > 0 }"
        >
          <li class="nav-item" v-for="sub in subRoute" :key="sub.path">
            <router-link tag="a" class :to="sub.to">
              <i :class="sub.icon" class="font-size-20"></i>
              <span class="item-name">{{ sub.name }}</span>
            </router-link>
            <!-- if nested menu -->
            <!-- <ul class="submenu" v-if="sub.children && sub.children.length > 0">
            <li v-for="child in children" :key="child.path">
              <router-link tag="a" class :to="child.to">
                <i :class="child.icon"></i>
                <span class="item-name">{{ child.name }}</span>
              </router-link>
            </li>
          </ul> -->
          </li>
        </ul>
      </div>
    </vue-perfect-scrollbar>
  </div>
  <!--=============== Left side End ================-->
</template>

<script>
import { isMobile } from "mobile-device-detect";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      subRoute: [],
      isDisplay: true,
      isMenuOver: false,
      isStyle: true,
      selectedParentMenu: "",
      openSubMenu: false,
      isMobile,
    };
  },
  mounted() {
    this.toggleSelectedParentMenu();
    window.addEventListener("resize", this.handleWindowResize);
    document.addEventListener("click", this.returnSelectedParentMenu);
    this.handleWindowResize();
  },

  beforeDestroy() {
    document.removeEventListener("click", this.returnSelectedParentMenu);
    window.removeEventListener("resize", this.handleWindowResize);
  },

  computed: {
    ...mapGetters(["getSideBarToggleProperties", "currentUserPermissions"]),
    ...mapGetters({
      getRoutes: "routes/getRoutes",
    }),
  },

  methods: {
    ...mapActions(["changeSidebarProperties"]),
    handleWindowResize() {
      if (window.innerWidth <= 1200) {
        this.changeSidebarProperties();
        this.openSubMenu = false;
      } else {
        if (!this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
      }
    },
    toggleSelectedParentMenu() {
      const currentParentUrl = this.$route.path.split("/").filter((x) => x !== "")[0];
      if (currentParentUrl !== undefined || currentParentUrl !== null) {
        this.selectedParentMenu = currentParentUrl.toLowerCase();
      }
    },
    toggleSubMenu(e) {
      let hasSubmenu = e.currentTarget.dataset.submenu;
      let parent = e.currentTarget.dataset.item;
      if (hasSubmenu) {
        this.selectedParentMenu = parent;
      } else {
        this.selectedParentMenu = parent;
      }
    },
    toggleMouseMove(e) {
      let hasSubmenu = e.currentTarget.dataset.submenu;
      let parent = e.currentTarget.dataset.item;
      if (hasSubmenu) {
        this.subRoute = this.getRoutes
          .find((x) => x.name === parent)
          .children.filter((x) => !x.hideInMenu);
        this.openSubMenu = true;
      } else {
        this.subRoute = [];
        this.openSubMenu = false;
      }
    },
    returnSelectedParentMenu() {
      if (!this.isMenuOver) {
        this.toggleSelectedParentMenu();
      }
    },
    toggleSidebarDropdwon(event) {
      let dropdownMenus = this.$el.querySelectorAll(".dropdown-sidemenu.open");
      event.currentTarget.classList.toggle("open");
      dropdownMenus.forEach((dropdown) => {
        dropdown.classList.remove("open");
      });
    },
    // function to set scss vars
    setVar() {
      let root = document.documentElement;
      root.style.setProperty("--primary", res.data.color);
      // change opacity of color
      // root.style.setProperty('--primary-opacity', 'rgba(--primary, 0.5)');
    },
  },
  watch: {
    isMenuOver(newValue) {
      // Update the CSS variable when the condition changes
      document.documentElement.style.setProperty(
        "--custom-sidebar-width",
        newValue ? "120px" : "60px"
      );
    },
  },
};
</script>

<style lang="scss">
.font-size-20 {
  font-size: 20px !important;
}
</style>
