var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',_vm._g(_vm._b({ref:"ValidationProvider_ref",scopedSlots:_vm._u([{key:"default",fn:function({ valid, errors }){return _c('b-form-group',{attrs:{"label":_vm.$attrs.label}},[_vm._t("default",null,{"attrs":{
        state:
          (_vm.serverErrors && _vm.serverErrors[_vm.$attrs.errorName]) || errors[0]
            ? false
            : valid && _vm.$refs.ValidationProvider_ref?.flags?.dirty
            ? true
            : null,
      }}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s((_vm.serverErrors && _vm.serverErrors[_vm.$attrs.errorName][0]) || errors[0])+" ")])],2)}}],null,true)},'ValidationProvider',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }