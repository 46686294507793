import Vue from 'vue';
import Vuex from 'vuex';
// import axios from 'axios'
import router from './../../router';
import store from '../../store/index.js';
import { i18n } from '../../plugins/i18n';

Vue.use(Vuex);

const state = {
  isAuthenticated: false,
  Permissions: [
    'users_view',
    'users_edit',
    'record_view',
    'users_delete',
    'users_add',
    'permissions_edit',
    'permissions_view',
    'permissions_delete',
    'permissions_add',
    'products_delete',
    'products_view',
    'barcode_view',
    'products_edit',
    'products_add',
    'expense_add',
    'expense_delete',
    'expense_edit',
    'expense_view',
    'transfer_delete',
    'transfer_add',
    'transfer_view',
    'transfer_edit',
    'adjustment_delete',
    'adjustment_add',
    'adjustment_edit',
    'adjustment_view',
    'Sales_edit',
    'Sales_view',
    'Sales_delete',
    'Sales_add',
    'Purchases_edit',
    'Purchases_view',
    'Purchases_delete',
    'Purchases_add',
    'Quotations_edit',
    'Quotations_delete',
    'Quotations_add',
    'Quotations_view',
    'payment_sales_delete',
    'payment_sales_add',
    'payment_sales_edit',
    'payment_sales_view',
    'Purchase_Returns_delete',
    'Purchase_Returns_add',
    'Purchase_Returns_view',
    'Purchase_Returns_edit',
    'Sale_Returns_delete',
    'Sale_Returns_add',
    'Sale_Returns_edit',
    'Sale_Returns_view',
    'payment_purchases_edit',
    'payment_purchases_view',
    'payment_purchases_delete',
    'payment_purchases_add',
    'payment_returns_edit',
    'payment_returns_view',
    'payment_returns_delete',
    'payment_returns_add',
    'Customers_edit',
    'Customers_view',
    'Customers_delete',
    'Customers_add',
    'unit',
    'currency',
    'category',
    'backup',
    'warehouse',
    'brand',
    'setting_system',
    'Warehouse_report',
    'Reports_quantity_alerts',
    'Reports_profit',
    'Reports_suppliers',
    'Reports_customers',
    'Reports_purchase',
    'Reports_sales',
    'Reports_payments_purchase_Return',
    'Reports_payments_Sale_Returns',
    'Reports_payments_Purchases',
    'Reports_payments_Sales',
    'Suppliers_delete',
    'Suppliers_add',
    'Suppliers_edit',
    'Suppliers_view',
    'Pos_view',
    'product_import',
    'customers_import',
    'Suppliers_import',
    'view_employee',
    'add_employee',
    'edit_employee',
    'delete_employee',
    'company',
    'department',
    'designation',
    'office_shift',
    'attendance',
    'leave',
    'holiday',
    'Top_products',
    'Top_customers',
    'shipment',
    'users_report',
    'stock_report',
  ],
  user: {
    username: 'William Castillo',
    currency: 'SAR',
  },
  loading: false,
  error: null,
  notifs: 1,
  Default_Language: 'en',
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  currentUser: (state) => state.user,
  currentUserPermissions: (state) => state.Permissions,
  loading: (state) => state.loading,
  notifs_alert: (state) => state.notifs,
  DefaultLanguage: (state) => state.Default_Language,
  error: (state) => state.error,
};

const mutations = {
  setLoading(state, data) {
    state.loading = data;
    state.error = null;
  },
  setError(state, data) {
    state.error = data;
    state.loggedInUser = null;
    state.loading = false;
  },
  clearError(state) {
    state.error = null;
  },

  setPermissions(state, Permissions) {
    state.Permissions = Permissions;
  },

  setUser(state, user) {
    state.user = user;
  },

  SetDefaultLanguage(state, Language) {
    i18n.locale = Language;
    store.dispatch('language/setLanguage', Language);
    state.Default_Language = Language;
  },

  Notifs_alert(state, notifs) {
    state.notifs = notifs;
  },

  logout(state) {
    state.user = null;
    state.Permissions = null;
    state.loggedInUser = null;
    state.loading = false;
    state.error = null;
  },
};

const actions = {
  //   async refreshUserPermissions(context) {
  //     await axios
  //       .get("GetUserAuth")
  //       .then((userAuth) => {
  //         let Permissions = userAuth.data.permissions;
  //         let user = userAuth.data.user;
  //         let notifs = userAuth.data.notifs;
  //         let default_language = userAuth.data.user.default_language;
  //         context.commit("setPermissions", Permissions);
  //         context.commit("setUser", user);
  //         context.commit("Notifs_alert", notifs);
  //         context.commit("SetDefaultLanguage", default_language);
  //       })
  //       .catch(() => {
  //         context.commit("setPermissions", null);
  //         context.commit("setUser", null);
  //         context.commit("Notifs_alert", null);
  //         context.commit("SetDefaultLanguage", "en");
  //       });
  //   },
  // logout({ commit }) {
  //   axios({ method: 'post', url: '/admin/logout', baseURL: '' }).then(
  //     (response) => {
  //       commit('logout');
  //       router.push('/login');
  //     }
  //   (userData) => {
  //     window.location.href = '/login';
  //   }
  // );
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
