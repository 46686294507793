
const asyncRoutes = [];
const context = require.context('./modules', false, /\.js$/);
context.keys().forEach((key) => {
    // order should be preserved for routes and dashboard first
    if (key.includes('dashboard')) {
        asyncRoutes.unshift(context(key).default);
    } else{
        asyncRoutes.push(context(key).default);

    }

});

export {asyncRoutes}
