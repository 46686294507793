export default {
  path: "/coupons",
  name: "coupons",
  component: () => import("@/views/coupons"),
  //mdiAccountOutline
  icon : "mdi mdi-ticket-percent-outline",
  to : "/coupons",
  hiddenChildren: true,
  meta: {
    permission: "Index-coupon",
  },
} 