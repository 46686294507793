export default {
  path: "/employees",
  name: "employees",
  component: () => import("@/views/employees"),
  to: "/employees",
  icon: "mdi mdi-account-outline",
  hiddenChildren: true,
  meta: {
    permission: "Index-employee",
  },
};
