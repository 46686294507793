<template>
  <div>
    <b-modal
      no-close-on-backdrop
      centered
      :visible="dialog"
      button-size="lg"
      size="sm"
    >
      <template #modal-title> </template>
      <div class="d-block text-center h5">
        <span> {{ $t("Are you sure you want to delete this item?") }} </span>
      </div>
      <template #modal-footer>
        <b-button class="mt-3" @click="$emit('update:dialog', false)">
          cancel
        </b-button>
        <b-button class="mt-3" variant="danger" @click="handleDelete"
          >delete</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    // requestName: {
    //   type: String,
    //   default: ""
    // },
    // tableItem: {
    //   type: Object,
    //   default: () => ({ id: "" })
    // },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleDelete() {
      this.$emit("deleteConfirmed");
      this.$emit("update:dialog", false);
    },
  },
};
</script>
