
import Vue from "vue";
import ZkTable from "vue-table-with-tree-grid";
import CustomSelect from "@/components/custom-select.vue";
import GenericDialog from "@/components/GenericDialoga";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import customizer from "@/components/common/customizer.vue";
import Breadcumb from "@/components/breadcumb";
import { VueGoodTable } from "vue-good-table";

Vue.component(ZkTable.name, ZkTable);
Vue.component("GenericDialog", GenericDialog);
Vue.component("v-select", vSelect);
Vue.component("CustomSelect", CustomSelect);
Vue.component("vue-perfect-scrollbar", VuePerfectScrollbar);
Vue.component("vue-upload-multiple-image", VueUploadMultipleImage);
Vue.component("customizer", customizer);
Vue.component("breadcumb", Breadcumb);
Vue.component("vue-good-table", VueGoodTable);
