import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./plugins/i18n";
import "@/plugins/vee-validate";
import "@/plugins/axios";
import "@/plugins/bootstrap";
import "@/bootstrap.js";

// import VueHtmlToPaper from "vue-html-to-paper";
// const options = {
//   name: "_blank",
//   specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
//   styles: [
//     "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
//     "https://unpkg.com/kidlat-css/css/kidlat.css",
//   ],
//   timeout: 1000, // default timeout before the print window appears
//   autoClose: true, // if false, the window will not close after printing
//   windowTitle: window.document.title, // override the window title
// };

// Vue.use(VueHtmlToPaper, options);
// import "~bootstrap/scss/bootstrap.scss";

// import Meta from "vue-meta";
// Vue.use(Meta, {
//   keyName: "metaInfo",
//   attribute: "data-vue-meta",
//   ssrAttribute: "data-vue-meta-server-rendered",
//   tagIDKeyName: "vmid",
//   refreshOnceOnNavigation: true,
// });

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  created() {
    let permissions = JSON.parse(localStorage.getItem("permissions"));
    if (permissions?.length === 0 ) {
      // empty permssion mean is admin -> allow all routes
      this.$store.dispatch("user/routesGenerator" , true);
    }else if(permissions?.length > 0){
      this.$store.commit("user/SET_PERMISSIONS", permissions);
      this.$store.dispatch("user/routesGenerator" );
    }
  },
  render: (h) => h(App),
}).$mount("#app");
