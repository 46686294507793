export default {
    path: "/expense",
    name: "expense",
    component: () => import("@/views/expense"),
    // mdiAccountCash 
    icon : "mdi mdi-account-cash",
    to : "/expense",
    hiddenChildren: true,
    meta: {
        permission: "Index-expense",
      },
} 